import React from "react";
import { Box, Text } from "grommet";
import { TextPageLayout } from "../textPageLayout";
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import {useSelector} from "react-redux"

export const ContactPageComponent = () => {
    const language = useSelector(state=> state.language.language);
    const textArray = language==="SWE"?textSwe:textEng;
    const headingText = language==="SWE"?"Om initiativet":"About the initiative";
    const content =
    <Box gap="small">
        <Text color="darkGreen" weight="bold">{textArray[0]}</Text>
    <Text color="darkGreen">
    {textArray[1]}
    </Text>
    <Text color="darkGreen">
    {textArray[2]}
    </Text>
    <Text color="darkGreen" weight="bold">
    {textArray[3]}
    </Text>
    </Box>;

    return (
       <TextPageLayout header={headingText} content={content}/>
    )
}
